import React, { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency,setCountryCode } from "../redux/currencySlice"; // Adjust the path accordingly
import Flag from "react-world-flags";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

const countries = [
  { code: "SA", name: "Saudi Arabia", currency: "SAR", flagCode: "SA" },
  { code: "KW", name: "Kuwait", currency: "KWD", flagCode: "KW" },
  { code: "AE", name: "United Arab Emirates", currency: "AED", flagCode: "AE" },
  { code: "BH", name: "Bahrain", currency: "BHD", flagCode: "BH" },
  { code: "OM", name: "Oman", currency: "OMR", flagCode: "OM" },
  { code: "GB", name: "United Kingdom", currency: "GBP", flagCode: "GB" },
  { code: "US", name: "United States", currency: "USD", flagCode: "US" },
  { code: "AU", name: "Australia", currency: "AUD", flagCode: "AU" },
  { code: "FR", name: "France", currency: "EUR", flagCode: "FR" },
  { code: "DE", name: "Germany", currency: "EUR", flagCode: "DE" },
  { code: "NZ", name: "New Zealand", currency: "NZD", flagCode: "NZ" },
  { code: "EG", name: "Egypt", currency: "EGP", flagCode: "EG" },
  { code: "JO", name: "Jordan", currency: "JOD", flagCode: "JO" },
  { code: "MY", name: "Malaysia", currency: "MYR", flagCode: "MY" },
  { code: "RU", name: "Russia", currency: "RUB", flagCode: "RU" },
  { code: "CH", name: "Switzerland", currency: "CHF", flagCode: "CH" },
  { code: "CA", name: "Canada", currency: "CAD", flagCode: "CA" },
  { code: "LB", name: "Lebanon", currency: "LBP", flagCode: "LB" },
  { code: "SG", name: "Singapore", currency: "SGD", flagCode: "SG" },
];

const CustomSelect = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedCurrency = useSelector((state) => state.country.currency); // Get the current currency from Redux
  const [isOpen, setIsOpen] = useState(false); // Track if the dropdown is open
  const [searchValue, setSearchValue] = useState(""); // For filtering country list
  const dropdownRef = useRef(null); // Ref to track the dropdown container

  const selectedCountry = countries.find(country => country.currency === selectedCurrency) || countries[0];

  // Toggle dropdown
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle selection of a country
  const handleSelectCountry = (country) => {
    dispatch(setCurrency(country.currency)); // Dispatch Redux action to change currency
    dispatch(setCountryCode(country.code))
    setIsOpen(false); // Close dropdown when a country is selected
  };

  // Filter countries based on search input
  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if click is outside
      }
    };

    // Add event listener to document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="max-w-xs relative bg-black bg-opacity-30 rounded mt-2 md:mt-0">
      {/* Display selected country when dropdown is closed */}
      <div
        onClick={toggleDropdown}
        className="flex items-center justify-between rounded-lg p-2 cursor-pointer relative"
      >
        <div className="flex items-center">
          <Flag code={selectedCountry.flagCode} className="w-8 h-6 mr-3" />
        </div>
        <FontAwesomeIcon icon={faAngleDown} className="w-5 text-white" />
      </div>

      {/* Dropdown content (search input and country list) */}
      {isOpen && (
        <div
          className="absolute left-[-80px] top-14 w-[250px] bg-white border border-gray-300 rounded-lg mt-1 z-50 shadow-lg"
          style={{ zIndex: 9999 }} // High z-index for visibility
        >
          {/* Search Input */}
          <div className="p-2">
            <input
              type="text"
              placeholder={t("searchCountry")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-dark"
            />
          </div>

          {/* Country list */}
          <ul className="max-h-60 overflow-y-auto">
            {filteredCountries.length > 0 ? (
              filteredCountries.map((country) => (
                <li
                  key={country.code}
                  onClick={() => handleSelectCountry(country)}
                  className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                >
                  <Flag code={country.flagCode} className="w-6 mr-3" />
                  <span className="text-dark">
                    {country.name} - {country.currency}
                  </span>
                </li>
              ))
            ) : (
              <li className="p-3 text-center text-gray-500">{t("noResultsFound")}</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
