import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  setSubscriptionPeriod, setFirstOrderDate } from '../redux/orderSlice'; // Import the actions
import { useTranslation } from "react-i18next";

const MonthlySubscription = () => {
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Get state from Redux
    const { product, quantity, price, subscriptionPeriod, firstOrderDate } = useSelector(state => state.order);
    const  currency= useSelector(state => state.country.currency);
    // Set default date to today when component mounts
    useEffect(() => {
        if (!firstOrderDate) {
            const today = new Date().toISOString().split("T")[0];
            dispatch(setFirstOrderDate(today)); // Set default date to today in the Redux store
        }
    }, [dispatch, firstOrderDate]);

    const handleNext = () => {
        const selectedDate = new Date(firstOrderDate);
        const today = new Date();

        // Validation for date and subscription period
        if (!firstOrderDate) {
            setError(t("chooseFirstOrderDeliveryDate"));
            return;
        }

        if (selectedDate < today) {
            setError(t("deliveryDateMustBeInFuture"));
            return;
        }

        setError(""); // Clear error if validation passes
        
        setLoading(true); // Start loading state
        // Navigate to the next page and pass the required state
        navigate('/payment');
        setLoading(false); // Stop loading after navigation
    };

    return (
        <div className="min-h-screen flex justify-center items-start bg-gray-10 pt-10">
            <div className="bg-white p-8 rounded-lg shadow-lg w-[90%] sm:w-[60%] lg:w-[70%] h-auto">
                <h1 className="text-2xl font-bold text-center mb-6">{t("monthlySubscription")}</h1>
                <p className="text-lg text-center mb-8">
                    {t("chooseSubscriptionPeriodAndFirstOrderDate")}
                </p>

                {error && <p className="text-red-500 text-center mb-4">{error}</p>}

                <div className="flex flex-col gap-10">
                    <div className="flex flex-col-reverse sm:flex-row gap-10">
                        <div className="w-full sm:w-1/2">
                            {/* Subscription Period */}
                            <div className="mb-6">
                                <label className="block font-semibold mb-2">{t("subscriptionPeriod")}</label>
                                <select
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-primary-500"
                                    value={subscriptionPeriod}
                                    onChange={(e) => dispatch(setSubscriptionPeriod(Number(e.target.value)))}
                                >
                                    {Array.from({ length: 11 }, (_, i) => i + 2).map((month) => (
                                        <option key={month} value={month}>
                                            {month} {t("months")}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* First Order Date */}
                            <div className="mb-6">
                                <label className="block font-semibold mb-2">{t("firstOrderDeliveryDate")}</label>
                                <input
                                    type="date"
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-primary-500"
                                    value={firstOrderDate}
                                    onChange={(e) => dispatch(setFirstOrderDate(e.target.value))}
                                />
                            </div>
                        </div>

                        {/* Product Info */}
                        <div className="bg-gray-10 p-4 rounded-lg w-full sm:w-1/2">
                            <p className="mt-3">{t("product")}: {product}</p>
                            <hr className="my-4 bg-black opacity-[40%]" />
                            <p>{t("quantity")}: {quantity} {t("carton")}</p>
                            <hr className="my-4 bg-black opacity-[40%]" />
                            <p className="mb-3">{t("totalPrice")}: {price}{" "}{currency}</p>
                        </div>
                    </div>

                    <button
                        onClick={handleNext}
                        disabled={loading}
                        className={`w-full ${loading ? "bg-gray-400" : "bg-primary-500"} text-white p-3 rounded-lg hover:bg-primary-600 transition`}
                    >
                        {loading ? t("loading") : t("next")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MonthlySubscription;
