import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import meccaData from "../jsons/mecca.json";
import pilgrimsData from "../jsons/umrah.json";
import medinaData from "../jsons/madina.json";
import polygonData from "../jsons/polygonData"; // Importing the polygon data
import SelectedMosquesList from "../components/SelectedMosquesList";
import MosqueModal from "../components/Modals/MosqueModal";
import OrderModal from "../components/Modals/OrderModal";
import NotAvailabePlace from "../components/Modals/NotAvailabePlace";
import CategorySelector from "../components/CategorySelector";
import SearchInput from "../components/SearchInput";
import kaaba from "../assets/images/kaabaImg.png";
import blue_marker from "../assets/images/blue-marker.png";
import mosque_marker from "../assets/images/mosque-marker.png";
import * as turf from "@turf/turf";
import { getCoordinates } from "../utils/functions";
import { useTranslation } from "react-i18next";
import Navbar from '../components/Navbar'

const MosqueMap = ({onLanguageChange}) => {
  const { t } = useTranslation();
  const [selectedMosque, setSelectedMosque] = useState(null);
  const [mosques, setMosques] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [center, setCenter] = useState({ lat: 25.276987, lng: 55.296249 });
  const [showModal, setShowModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showNotAvailabePlaceModal, setShowNotAvailabePlaceModal] =
    useState(false);
  const [selectedMosquesList, setSelectedMosquesList] = useState([]);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const kaabaPosition = { lat: 21.4225, lng: 39.8262 }; // Latitude and longitude of the Kaaba
  const [loadError, setLoadError] = useState(false);
  const [defaultIconSize, setDefaultIconSize] = useState(null);
  const [selectedIconSize, setSelectedIconSize] = useState(null);

  const mapContainerStyle = {
    width: "100%",
    height: "93vh",
  };

  const handleMapLoad = (map) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(userLocation);
        },
        () => {
          loadDefaultMosques();
        }
      );
    } else {
      loadDefaultMosques();
    }
    map.setOptions({ mapId: "1fccaf67bfe984c1" });

    map.addListener("click", (event) => {
      if (event.placeId) {
        event.stop();
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails({ placeId: event.placeId }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSelectedMosque(place);
            comparePointWithPolygons(
              place.geometry.location.lat(),
              place.geometry.location.lng()
            );
          }
        });
      }
    });
  };

  const loadDefaultMosques = () => {
    let selectedMosques = [];
    if (selectedCategory === "mecca") {
      selectedMosques = meccaData.results;
    } else if (selectedCategory === "pilgrims") {
      selectedMosques = pilgrimsData.results;
    } else if (selectedCategory === "medina") {
      selectedMosques = medinaData.results;
    } else {
      selectedMosques = [
        ...meccaData.results,
        ...medinaData.results,
        ...pilgrimsData.results,
      ];
    }

    setMosques(selectedMosques);

    if (selectedMosques.length > 0) {
      const firstMosqueLocation = selectedMosques[0].geometry.location;
      setCenter({
        lat: firstMosqueLocation.lat,
        lng: firstMosqueLocation.lng,
      });
    }
  };

  useEffect(() => {
    const handleOnline = () => setLoadError(false);
    const handleOffline = () => setLoadError(true);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  useEffect(() => {
    if (selectedCategory) {
      loadDefaultMosques();
    }
  }, [selectedCategory]);

  useEffect(() => {
    // Check if Google Maps is available
    if (typeof window !== "undefined" && window.google) {
      setDefaultIconSize({
        url: mosque_marker,
        scaledSize: new window.google.maps.Size(30, 30),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 30),
      });
      setSelectedIconSize({
        url: blue_marker,
        scaledSize: new window.google.maps.Size(33, 33),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 30),
      });
    }
  }, [window.google]); // Run this effect when the Google Maps API is available

  const handleMarkerClick = (mosque) => {
    setSelectedMosque(mosque);
    comparePointWithPolygons(
      mosque.geometry.location.lat,
      mosque.geometry.location.lng
    );
  };

  const addMosqueToList = () => {
    if (
      !selectedMosquesList.some((m) => m.place_id === selectedMosque.place_id)
    ) {
      setSelectedMosquesList([...selectedMosquesList, selectedMosque]);
    }
    setShowModal(false);
  };

  const removeMosqueFromList = (mosqueId) => {
    setSelectedMosquesList(
      selectedMosquesList.filter((m) => m.place_id !== mosqueId)
    );
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
  };

  const handleSearch = (query) => {
    const lat = query.geometry.location.lat();
    const lng = query.geometry.location.lng();
    setCenter({ lat, lng });
  };

  const comparePointWithPolygons = (lat, lng) => {
    const point = turf.point([lng, lat]);
    let isInsideAnyPolygon = false;

    polygonData.forEach((region) => {
      const coords = region.polygon_arr.map((coord) => {
        const [lat, lng] = coord.split(",").map(Number);
        return [lng, lat];
      });

      if (coords.length >= 3) {
        coords.push(coords[0]);
        const polygon = turf.polygon([coords]);

        if (turf.booleanPointInPolygon(point, polygon)) {
          isInsideAnyPolygon = true;
        }
      }
    });

    if (isInsideAnyPolygon) {
      setShowModal(true);
    } else {
      setShowNotAvailabePlaceModal(true);
    }
  };

  return (
    <>
    <Navbar onLanguageChange={onLanguageChange} />
    <div className="relative w-[100%]">
      <div className={`overlay ${showModal ? "active" : ""}`} />
      <LoadScript
            googleMapsApiKey={googleMapsApiKey}
            libraries={["places"]}
          >
      <div className={`content ${showModal ? "blur" : ""}`}>
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-10 w-[90%] flex flex-col md:flex-row gap-5 items-start">
          <div className="w-full md:w-1/4">
            <SelectedMosquesList
              selectedMosquesList={selectedMosquesList}
              removeMosqueFromList={removeMosqueFromList}
              setShowOrderModal={setShowOrderModal}
            />
          </div>

          <div className="w-full md:w-1/4">
            <SearchInput
              placeholder={t("enterTheCityOrMosqueName")}
              onSearch={handleSearch}
            />
          </div>

          <div className="w-full md:w-1/2">
            <CategorySelector
              handleCategoryClick={handleCategoryClick}
              selectedCategory={selectedCategory}
            />
          </div>
        </div>
      {/* Responsive Complete the Order Button */}
      {selectedMosquesList.length > 0 && (
        <button
          onClick={() => setShowOrderModal(true)}
          className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-10 px-6 py-2 w-10/12 sm:w-1/4 bg-primary-500 text-white rounded-lg hover:bg-blue-700"
        >
          {t("completeTheOrder")}
        </button>
      )}

        <div style={{ position: "relative", zIndex: 2 }}>
        
             {loadError ? (
              <div>{t("errorLoadingMap")}</div>
            ) : (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={14}
              onLoad={handleMapLoad}
              options={mapOptions}
            >
              {mosques.map((mosque) => (
                <Marker
                  key={mosque.place_id}
                  position={{
                    lat: mosque.geometry.location.lat,
                    lng: mosque.geometry.location.lng,
                  }}
                  icon={
                    selectedMosquesList.some(
                      (m) => m.place_id === mosque.place_id
                    )
                      ? selectedIconSize
                      : defaultIconSize
                  } // Use custom icons based on selection
                  onClick={() => handleMarkerClick(mosque)}
                />
              ))}
              {selectedMosquesList.length > 0 ? (
                selectedMosquesList.map((selectedMosque) => {
                  const { lat, lng } = getCoordinates(
                    selectedMosque.geometry.location
                  );

                  return (
                    <Marker
                      key={selectedMosque.place_id}
                      position={{
                        lat,
                        lng,
                      }}
                      icon={selectedIconSize} // Use the blue icon for selected mosques
                      onClick={() => handleMarkerClick(selectedMosque)}
                    />
                  );
                })
              ) : (
                <></> // Render nothing if there are no selected mosques
              )}

              <OverlayView
                position={kaabaPosition}
                mapPaneName={OverlayView.OVERLAY_LAYER}
              >
                <div style={{ zIndex: 1 }}>
                  <img
                    src={kaaba}
                    alt="Kaaba"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              </OverlayView>
            </GoogleMap>
            )}
          
        </div>
      </div>
      </LoadScript>
      {showModal && selectedMosque && (
        <MosqueModal
          selectedMosque={selectedMosque}
          addMosqueToList={addMosqueToList}
          setShowModal={setShowModal}
        />
      )}

      {showOrderModal && (
        <OrderModal
          selectedPlacesList={selectedMosquesList}
          setShowOrderModal={setShowOrderModal}
        />
      )}

      {showNotAvailabePlaceModal && (
        <NotAvailabePlace
          setShowNotAvailabePlaceModal={setShowNotAvailabePlaceModal}
          handleCategoryClick={handleCategoryClick}
          selectedCategory={selectedCategory}
        />
      )}
    </div>
    </>
  );
};

export default MosqueMap;
