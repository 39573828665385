import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HyperpayPaymentModal = ({ selectedMethod, showCheckoutModal, onClose, checkoutId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false); // Success state
  const selectedCountryCode = useSelector((state) => state.country.countryCode);
  const selectedCurrency = useSelector((state) => state.country.currency);
  const paymentData= useSelector((state) => state.payment);
console.log(paymentData,"paymetdataaaaa");
  const mapSelectedMethodToHyperpay = (method) => {
    switch (method) {
      case 'ApplePay':
        return 'APPLEPAY';
      case 'VisaMaster':
        return 'VISA MASTER';
      case 'Mada':
        return 'MADA';
      case 'StcPay':
        return 'STC_PAY';
      case 'knet':
        return 'KNET';
      default:
        return method; // Return as is if it doesn't match any known method
    }
  };

  useEffect(() => {
    if (showCheckoutModal && checkoutId) {
            // Set wpwlOptions for Apple Pay, including the merchant identifier
            window.wpwlOptions = {
              onReady: function () {
                if (selectedMethod === 'ApplePay') {
                  window.wpwlOptions.applePay = {
                    countryCode: selectedCountryCode,  // Set your country code
                    currencyCode: selectedCurrency, // Set your currency code
                    merchantIdentifier: 'merchant.com.dd.sabeel',  // Apple Pay Merchant ID
                    displayName: 'Sabeel',
                    version: 3,
                    total: {
                      label: 'Sabeel Order',
                      amount:paymentData.isCouponApplied ? paymentData.totalAfterCoupon.toFixed(2) : paymentData.total.total.toFixed(2) || '0.00', // Total amount as a string
                    },
                    merchantCapabilities: ["supports3DS"],
                    supportedNetworks: ["visa", "masterCard","mada"]
                    
                  };
                  console.log("Apple Pay Configuration:", window.wpwlOptions.applePay); // Log configuration
                }
              },
              onError: function (error) {
                console.error("Apple Payment Error:", error);
                toast.error(`Apple Payment Error: ${JSON.stringify(error)}`);
              },
            };
      // Dynamically load Hyperpay's payment widget script
      const script = document.createElement('script');
       script.src = `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
     //script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.async = true;

      script.onload = () => {
        // Check if the script loaded successfully
        try {
          if (typeof window.wpwlOptions !== 'undefined') {
            setLoading(false); // Set loading to false once the script is loaded
          } else {
            setError(t("failedToLoadPaymentWidget"));
          }
        } catch (error) {
         // console.error('Error during script load:', error);
          setError(t("errorOccurredWhileLoadingPaymentWidget"));
        }
      };
      
      script.onerror = (e) => {
        //console.error('Script failed to load:', e);
        setError(t("failedToLoadHyperpayScript"));
      };

      document.body.appendChild(script);
      
      // Clean up the script on modal close
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [showCheckoutModal, checkoutId]);


  return (
    <>
    <ToastContainer />
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" dir='ltr'>
        <div className="bg-white rounded-lg shadow-lg w-full md:w-1/2 lg:w-2/5 max-h-full overflow-auto m-6 relative">
          <button onClick={onClose} className='m-2'>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {loading ? (
             <div className="flex justify-center items-center">
        
             <FontAwesomeIcon icon={faSpinner} spin className="mx-2" />
            <div className='m-5 text-center'>{t("loadingPaymentWidget")}</div></div>
          ) : error ? (
            <div style={{ color: 'red' }} className='m-5 text-center'>{error}</div>
          ) : (
            // Render the payment widget form inside the modal
            <div className="flex justify-center items-center p-4">
            <form
              // action="http://localhost:3000/payment-result"
              action="https://sabeel.online/payment-result"
              
              className="paymentWidgets"
              data-brands={mapSelectedMethodToHyperpay(selectedMethod)}
            ></form></div>
          )}
        </div>
      </div>

     
    </>
  );
};

export default HyperpayPaymentModal;
