import { api_url } from "../config/config";
import { authorizedHttpJson, httpJson } from "../config/http";




export async function getProducts(countryCode) {
  return await authorizedHttpJson.get(`${api_url}/products`,  {
    headers: {
      'country-code': countryCode 
    }
  });
}

