/* global ApplePaySession */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { getPaymentOnlineStatus } from "../api/settings";
import bankIcon from "../assets/images/bank.png";
import madaIcon from "../assets/images/mada.png";
import visaIcon from "../assets/images/visa.png";
import stcIcon from "../assets/images/stc.png";
import applePay from "../assets/images/ApplePay.png";
import knet from "../assets/images/knet.png";

// Mapping payment method names to corresponding icons
const paymentIcons = {
  ApplePay: applePay,
  VisaMaster: visaIcon,
  Mada: madaIcon,
  StcPay: stcIcon,
  knet: knet,
};

const PaymentMethod = ({ selectedMethod, handleSelect }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const selectedCountryCode = useSelector((state) => state.country.countryCode);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const [isSafari, setIsSafari] = useState(false); // New state for Safari detection

  useEffect(() => {
    // Check if the browser is Safari
    const userAgent = window.navigator.userAgent;
    setIsSafari(/^((?!chrome|android).)*safari/i.test(userAgent)); // Check if Safari

    // Check if Apple Pay is available (usually you check if the user's device/browser supports it)
    if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
      setIsApplePayAvailable(true);
    } else {
      setIsApplePayAvailable(false);
    }
  }, []);

  useEffect(() => {
    // Fetch payment methods and filter based on status
    getPaymentOnlineStatus(selectedCountryCode)
      .then((data) => {
        // Filter payment methods where status is true
        let activeMethods = data.data.data.filter((method) => method.status);

        // Remove duplicates by 'slug', ignoring case differences
        const seenSlugs = new Set();
        activeMethods = activeMethods.filter((method) => {
          const normalizedSlug = method.slug.toLowerCase(); // Normalize to lowercase
          if (seenSlugs.has(normalizedSlug)) {
            return false; // Duplicate found, exclude this item
          }
          seenSlugs.add(normalizedSlug);
          return true; // Include the unique item
        });

        setPaymentMethods(activeMethods);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedCountryCode]);

  // Render the static bank method
  const renderBankMethod = () => {
    const isSelected = selectedMethod === "bank";
    return (
      <div
        onClick={() => handleSelect("bank")}
        className={`border p-4 rounded-lg flex items-center justify-between cursor-pointer ${
          isSelected ? "border-primary-500" : "border-gray-300"
        }`}
      >
        <div className="flex items-center gap-4">
          <img src={bankIcon} alt="تحويل بنكي" className="w-8 h-8" />
          <span> تحويل بنكي</span>
        </div>
        {isSelected && (
          <FontAwesomeIcon icon={faCheckCircle} className="text-primary-500" />
        )}
      </div>
    );
  };

  // Render dynamic payment methods, excluding Apple Pay if not on Safari
  const renderPaymentMethods = () => {
    return paymentMethods
       .filter((method) => !(method.slug === "ApplePay" && !isSafari)) // Exclude Apple Pay if not Safari
     // .filter((method) => !(method.slug === "ApplePay"))
      .map((method) => {
        const isSelected = selectedMethod === method.slug;
        const icon = paymentIcons[method.slug] || bankIcon; // Fallback to bankIcon if no match

        return (
          <div
            key={method.id}
            onClick={() => handleSelect(method.slug)}
            className={`border p-4 rounded-lg flex items-center justify-between cursor-pointer ${
              isSelected ? "border-primary-500" : "border-gray-300"
            }`}
          >
            <div className="flex items-center gap-4">
              <img src={icon} alt={method.name} className="w-12 h-10" />
              <span>{method.name}</span>
            </div>
            {isSelected && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-primary-500"
              />
            )}
          </div>
        );
      });
  };

  return (
    <>
      {/* Render the static bank method */}
      {renderBankMethod()}

      {/* Render the dynamic payment methods */}
      {renderPaymentMethods()}
    </>
  );
};

export default PaymentMethod;
