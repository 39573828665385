import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/authSlice';
import { useNavigate, Link } from 'react-router-dom';
import order_home_state from '../assets/images/order_home_state.png';  
import order_mosque_state from '../assets/images/order_mosque_state.png'; 
import service_type_header from '../assets/images/service_type_header.png'; 
import { useTranslation } from 'react-i18next';
import { OrderContext } from '../OrderContext'; // Adjust the import path to your context
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes} from '@fortawesome/free-solid-svg-icons';


const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsMosqueSelected } = useContext(OrderContext); // Get setIsMosqueSelected from context
  const [showModal, setShowModal] = useState(false); // Control the visibility of modal



  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Logout function
  const handleLogout = () => {
    dispatch(logout());
    navigate('/login'); // Navigate to login after logout
  };

  const handleServiceSelect = (isMosque) => {
    setIsMosqueSelected(isMosque); // Update the context state based on selection
  };

  return (
    <div className="bg-gray-10 flex flex-col items-center min-h-screen pb-10">
      {/* Main Container */}
      <div className="w-[90%] sm:w-[80%] bg-white rounded mb-5">
        {/* Service Type Header Image */}
        <img 
          src={service_type_header} 
          alt="service type header" 
          className="w-full mb-5" 
        />
        
        {/* Choose Service Text */}
        <div className="text-center mb-5">
          <p className="font-bold text-xl sm:text-2xl">{t("chooseService")}</p>
        </div>
      </div>
      
      {/* Services Options */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-10 md:gap-20 w-[90%] sm:w-[80%]">
        
        {/* Mosques - Navigate to /mosques-map */}
        <Link 
          to="/mosques-map" 
          className="w-[50%] md:w-[30%]" 
          onClick={() => handleServiceSelect(true)} // Set mosque selection
        >
          <div className="bg-white p-4 rounded shadow-lg text-center cursor-pointer">
            <img 
              src={order_mosque_state} 
              alt="Mosques" 
              className="w-full mb-3"
            />
            <p className="text-primary-500 font-bold">{t("donateMosques")}</p>
          </div>
        </Link>
        
        {/* Homes - Navigate to /map */}
        <Link 
          to="/map" 
          className="w-[50%] md:w-[30%]" 
          onClick={() => handleServiceSelect(false)} // Set home selection
        >
          <div className="bg-white p-4 rounded shadow-lg text-center cursor-pointer">
            <img 
              src={order_home_state} 
              alt="Homes" 
              className="w-full mb-3"
            />
            <p className="text-primary-500 font-bold">{t("donateHomes")}</p>
          </div>
        </Link>
      </div>

      {/* Modal */}
      {showModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg shadow-lg text-center w-[80%] md:w-[70%] relative">
      
      {/* Close button as X icon */}
      <FontAwesomeIcon 
        icon={faTimes} 
        className="absolute top-4 right-4 text-gray-500 cursor-pointer text-2xl" 
        onClick={closeModal} 
        aria-label="Close modal"
      />

    </div>
  </div>
)}
    </div>
  );
};

export default Home;
