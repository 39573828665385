import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import SearchInput from "../components/SearchInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Import the X icon
import OrderModal from '../components/Modals/OrderModal';
import polygonData from '../jsons/polygonData';
import * as turf from '@turf/turf';
import NotAvailableHouseModal from '../components/Modals/NotAvailableHouseModal'; 
import { useTranslation } from "react-i18next";
import Navbar from '../components/Navbar'

const HousesMap = ({onLanguageChange}) => {
  const { t } = useTranslation();
  const [center, setCenter] = useState({ lat: 25.276987, lng: 55.296249 });
  const [showModal, setShowModal] = useState(false);
  const [showNotAvailabePlaceModal,setShowNotAvailabePlaceModal]=useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [loadError, setLoadError] = useState(false);

  const mapContainerStyle = {
    width: "100%",
    height: "93vh",
  };

  const handleMapLoad = (map) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCenter(userLocation);
      });
    }

    map.addListener("click", (event) => {
      if (event.placeId) {
        event.stop();
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails({ placeId: event.placeId }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSelectedPlace(place);
            comparePointWithPolygons(place.geometry.location.lat(), place.geometry.location.lng());
          }
        });
      }
    });
  };

  const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
  };

  const handleSearch = (query) => {
    const lat = query.geometry.location.lat();
    const lng = query.geometry.location.lng();
    setCenter({ lat, lng });
  };

  const handleConfirm = () => {
    setShowModal(false);
  };

  const handleScriptLoadError = () => {
    console.error("Failed to load Google Maps scripts");
    setLoadError(true);
  };

  useEffect(() => {
    const handleOnline = () => setLoadError(false);
    const handleOffline = () => setLoadError(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const comparePointWithPolygons = (lat, lng) => {
    const point = turf.point([lng, lat]);
    let isInsideAnyPolygon = false;

    polygonData.forEach(region => {
      const coords = region.polygon_arr.map(coord => {
        const [lat, lng] = coord.split(',').map(Number);
        return [lng, lat];
      });

      if (coords.length >= 3) {
        coords.push(coords[0]);
        const polygon = turf.polygon([coords]);

        if (turf.booleanPointInPolygon(point, polygon)) {
          isInsideAnyPolygon = true;
        }
      }
    });

    if (isInsideAnyPolygon) {
      setShowModal(true);
    } else {
      setShowNotAvailabePlaceModal(true);
    }
  };

  return (
    <>
    <Navbar onLanguageChange={onLanguageChange} />
    <div className="relative w-[100%]">
      <div className={`overlay ${showModal ? "active" : ""}`} />
      <LoadScript
            googleMapsApiKey={googleMapsApiKey}
            libraries={["places"]}
            onError={handleScriptLoadError}
          >
      <div className={`content ${showModal ? "blur" : ""}`}>
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-10 w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] flex justify-center">
          <div className="w-full sm:w-2/3 md:w-1/2 lg:w-1/2">
            <SearchInput placeholder={t("searchOnYourLocation")} onSearch={handleSearch} />
          </div>
        </div>

        <div style={{ position: "relative", zIndex: 2 }}>
        
            {loadError ? (
              <div>{t("errorLoadingMap")}</div>
            ) : (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={13}
                onLoad={handleMapLoad}
                options={mapOptions}
              />
            )}
         
        </div>
      </div>
      </LoadScript>
      {/* Modal */}
      {showModal && selectedPlace && (
        <div className="modal fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded shadow-lg w-[90%] max-w-md relative">
            <div className="flex justify-between items-center mb-4">
              <button
                className="text-gray-600 ml-2"
                onClick={() => setShowModal(false)}
                aria-label="Close Modal"
              >
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </button>
              <h2 className="text-lg font-semibold flex-grow text-center">{t("location")}</h2>
            </div>

            <p className="mb-4">{selectedPlace.formatted_address}</p>
            <button
              className="bg-primary-500 text-white px-4 py-2 rounded w-[100%]"
              onClick={() => setShowOrderModal(true)}
            >
              {t("confirm")}
            </button>
          </div>
        </div>
      )}

      {showOrderModal && (
        <OrderModal 
          selectedPlacesList={[selectedPlace]} 
          setShowOrderModal={setShowOrderModal} 
        />
      )}

            {/* Not Available Place Modal */}
            {showNotAvailabePlaceModal && (
            <NotAvailableHouseModal onClose={() => setShowNotAvailabePlaceModal(false)} />
      )}
    </div>
    </>
  );
};

export default HousesMap;
