import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { getBanks } from "../api/settings"; // Adjust the import path as necessary

const BankSelect = ({ onBankSelect }) => {
  const [banks, setBanks] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [copiedText, setCopiedText] = useState(""); // State to track copied text

  useEffect(() => {
    // Fetch bank data from the API
    getBanks()
      .then((response) => {
        setBanks(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching banks:", error);
      });
  }, []);

  const handleBankSelect = (bankId) => {
    setSelectedBankId(bankId);
    onBankSelect(bankId); // Pass the selected bank ID to parent
    setDropdownOpen(false); // Close dropdown after selection
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text); // Set the copied text
    setTimeout(() => {
      setCopiedText(""); // Clear copied text after 2 seconds
    }, 2000);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="relative w-full">
      {/* Main select box */}
      <div
        className="bank-select-box border border-black border-opacity-[20%] rounded-lg px-4 py-3 cursor-pointer flex items-center justify-between w-full mt-4"
        onClick={toggleDropdown}
      >
        <span>
          {selectedBankId
            ? banks.find((bank) => bank.id === selectedBankId)?.name || "Select Bank"
            : "Select Bank"}
        </span>
        <FontAwesomeIcon icon={dropdownOpen ? faAngleUp : faAngleDown} />
      </div>

      {/* Dropdown options */}
      {dropdownOpen && (
        <div className="bank-options border border-black border-opacity-[20%] rounded-lg shadow-lg absolute bg-white w-full z-10 p-3">
          {banks?.map((bank) => (
            <div
              key={bank.id}
              className={`bank-item p-4 flex flex-col border rounded mt-3 ${selectedBankId === bank.id ? "border-primary-500" : "border-black border-opacity-[20%]"}`}
            >
              {/* Bank Name with Radio Button */}
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id={`bank-${bank.id}`}
                    name="bank"
                    checked={selectedBankId === bank.id}
                    onChange={() => handleBankSelect(bank.id)}
                    className="mr-2"
                  />
                  <label htmlFor={`bank-${bank.id}`} className="font-bold cursor-pointer">
                    {bank.name}
                  </label>
                </div>
                {/* Bank Logo */}
                <img src={bank.logo} alt={`${bank.name} logo`} className="w-20" />
              </div>

              {/* Bank Number and IBAN */}
              <div className="mt-3 flex justify-between">
                <p className="text-sm">{bank.number}</p>
                <button
                  className="copy-button text-primary-500 text-sm"
                  onClick={() => handleCopy(bank.number)}
                >
                  <span className={`bg-primary-500 bg-opacity-[20%] px-2 py-1 rounded ${copiedText === bank.number ? "text-success-50 bg-success-50 bg-opacity-[20%]" : ""}`}>
                    {copiedText === bank.number ? "Copied!" : "Copy"}
                  </span> 
                </button>
              </div>
              <div className="mt-5 flex justify-between">
                <p className="text-sm">{bank.iban}</p>
                <button
                  className="copy-button text-primary-500 text-sm"
                  onClick={() => handleCopy(bank.iban)}
                >
                  <span className={`bg-primary-500 bg-opacity-[20%] px-2 py-1 rounded ${copiedText === bank.iban ? "text-success-50 bg-success-50 bg-opacity-[20%]" : ""}`}>
                    {copiedText === bank.iban ? "Copied!" : "Copy"}
                  </span> 
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BankSelect;
