import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from '@reduxjs/toolkit'; // Import combineReducers
import authReducer from './authSlice';
import languageReducer from './languageSlice';
import currencyReducer from './currencySlice';
import orderReducer from './orderSlice';
import paymentReducer from './paymentSlice';

// Create a persist configuration
const persistConfig = {
  key: 'root', // The key for the persisted state
  storage, // The storage method
};

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  language: languageReducer,
  country: currencyReducer,
  order:orderReducer,
  payment:paymentReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor
export const persistor = persistStore(store);
