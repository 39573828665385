// RecipientModal.js
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const RecipientModal = ({ setShowModal,recipientDetails,setRecipientDetails }) => {
const { t } = useTranslation();
const orderDetails = useSelector((state) => state.order);
 const [currentPlaceIndex, setCurrentPlaceIndex] = useState(0);


  // Handler for changing input values
  const handleInputChange = (e, field) => {
    const updatedDetails = [...recipientDetails];
    updatedDetails[currentPlaceIndex][field] = e.target.value;
    setRecipientDetails(updatedDetails);
  };

  // Handle moving to the next place
  const handleNextPlace = () => {
    if (currentPlaceIndex < orderDetails.selectedPlacesList.length - 1) {
      setCurrentPlaceIndex(currentPlaceIndex + 1);
    } else {
      setShowModal(false); // Close modal after the last place
    }
  };

  // Handle moving back to the previous place
  const handlePreviousPlace = () => {
    if (currentPlaceIndex > 0) {
      setCurrentPlaceIndex(currentPlaceIndex - 1);
    }
  };


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
    <div className="bg-white pb-6 rounded-lg shadow-lg w-[80%] sm:w-[40%]">
      <h2 className="text-xl font-semibold mb-4 text-center bg-gray-15 p-4">
        {orderDetails.selectedPlacesList[currentPlaceIndex].name}
      </h2>
      <div className="p-4">
        <div className="mb-4">
          <input
            type="text"
            placeholder={t("recipientName")}
            className="w-full border border-gray-300 p-3 rounded-lg"
            value={recipientDetails[currentPlaceIndex].name}
            onChange={(e) => handleInputChange(e, "name")}
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder={t("recipientPhone")}
            className="w-full border border-gray-300 p-3 rounded-lg"
            value={recipientDetails[currentPlaceIndex].phone}
            onChange={(e) => handleInputChange(e, "phone")}
          />
        </div>

        {/* Show Mosque-specific option */}
        {orderDetails.isMosqueSelected && (
          <div className="mb-4">
            <div className="flex flex-col gap-4">
              <label>
                <input
                  type="radio"
                  name="mosqueGender"
                  value="men"
                  checked={
                    recipientDetails[currentPlaceIndex].mosqueGender ===
                    "men"
                  }
                  onChange={(e) => handleInputChange(e, "mosqueGender")}
                />{" "}
                {t("deliverToMen")}
              </label>
              <label>
                <input
                  type="radio"
                  name="mosqueGender"
                  value="women"
                  checked={
                    recipientDetails[currentPlaceIndex].mosqueGender ===
                    "women"
                  }
                  onChange={(e) => handleInputChange(e, "mosqueGender")}
                />{" "}
                {t("deliverToWomen")}
              </label>
            </div>
          </div>
        )}

        {/* Note Section */}
        <div className="mb-4">
          <label className="mb-4"> {t("addNotePrompt")}</label>
          <textarea
            className="w-full border border-gray-300 p-3 rounded-lg mt-3"
            placeholder={t("yourNote")}
            value={recipientDetails[currentPlaceIndex].note}
            onChange={(e) => handleInputChange(e, "note")}
          ></textarea>
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between gap-5">
          {currentPlaceIndex > 0 && (
            <button
              className="bg-gray-15 text-dark py-2 px-4 rounded w-1/2"
              onClick={handlePreviousPlace}
            >
              {t("goBack")}
            </button>
          )}
          <button
            className="bg-primary-500 text-white py-2 px-4 rounded w-1/2"
            onClick={handleNextPlace}
          >
            {currentPlaceIndex <
            orderDetails.selectedPlacesList.length - 1
              ? t("next")
              : t("close")}
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default RecipientModal;
