import React from 'react';
import mosque from "../assets/images/mosque.png";
import { useTranslation } from "react-i18next";

const OurVision = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center text-center p-6">
      <img src={mosque} alt="Mosque" className="w-1/2 md:w-2/3 mb-4" />
      <h2 className="text-2xl font-bold text-primary-500 mb-2 mt-5">{t("ourVision")}</h2>
      <p className="text-lg text-gray-700 max-w-[75%] mb-10">
       {t("ourVisionDescription")}
      </p>
    </div>
  );
};

export default OurVision;
