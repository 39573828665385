import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faGooglePlay,
  faInstagram,
  faSnapchat,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEye, faPhone, faShieldAlt, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <footer className="bg-[#0804FC14] text-center py-10 border-t border-black/20">
      {/* First Row: App Store & Google Play */}
      <div className="flex  gap-4 justify-center items-center mb-8 px-4">
        <a
          href="https://apps.apple.com/us/app/sabeel-%D8%B3%D8%A8%D9%8A%D9%84/id1463403733?ls=1"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-black text-white px-4 py-1 rounded flex gap-3 items-center w-48"
        >
          <FontAwesomeIcon icon={faApple} className="text-lg text-white" aria-label="Apple Store" />
          <div>
            <p>{t("downloadFrom")}</p>
            <p className="font-bold">{t("appStore")}</p>
          </div>
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.dd.sabeel"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-black text-white px-4 py-1 rounded flex gap-3 items-center w-48"
        >
          <FontAwesomeIcon icon={faGooglePlay} className="text-lg text-white" aria-label="Google Play Store" />
          <div>
            <p>{t("downloadFrom")}</p>
            <p className="font-bold">{t("googlePlay")}</p>
          </div>
        </a>
      </div>

      {/* Menu Toggle Button (Visible on Small Screens) */}
      <div className="sm:hidden mb-8 flex justify-center items-center">
        <button
          className=" text-black px-4 py-2 rounded flex items-center justify-center gap-2"
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          <FontAwesomeIcon icon={faAngleDown} className="text-lg" />
          
        </button>
      </div>

      {/* Menu Content (Hidden on Small Screens, Visible on Larger Screens or When Menu is Open) */}
      <div className={`${isMenuOpen ? "block" : "hidden"} sm:flex flex-col  justify-center items-center  mb-8`}>
        {/* Second Row: Social Media Icons */}
        <div className="flex justify-center gap-3 mb-8">
          <a href="https://www.instagram.com/sabeel_app/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FontAwesomeIcon icon={faInstagram} className="text-[2rem] text-[#E1306C] cursor-pointer" />
          </a>
          <a href="https://x.com/sabeel_app" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FontAwesomeIcon icon={faXTwitter} className="text-[2rem] text-[#1DA1F2] cursor-pointer" />
          </a>
          <a href="https://www.snapchat.com/add/sabeelapp" target="_blank" rel="noopener noreferrer" aria-label="Snapchat">
            <FontAwesomeIcon icon={faSnapchat} className="text-[2rem] text-[#FFFC00] cursor-pointer" />
          </a>
          <a href="https://www.youtube.com/channel/UCqVoyOmchyqnc2XogmKsFgg" target="_blank" rel="noopener noreferrer" aria-label="Youtube">
            <FontAwesomeIcon icon={faYoutube} className="text-[2rem] text-[#FF0000] cursor-pointer" />
          </a>
        </div>

        {/* Horizontal Line */}
        <hr className="border-t border-black/20 mb-8 ml-20 mr-20  sm:w-[100%] " />

        {/* Last Row: Links with Icons */}
        <div className="flex flex-col sm:flex-row justify-center items-center gap-3 sm:gap-8">
          <Link to="/our-vision" className="flex items-center gap-2 cursor-pointer text-primary-500" aria-label="Our Vision">
            <FontAwesomeIcon icon={faEye} className="text-gray-400" />
            {t("ourVision")}
          </Link>
          <Link to="/contact-us" className="flex items-center gap-2 cursor-pointer text-primary-500" aria-label="Contact Us">
            <FontAwesomeIcon icon={faPhone} className="text-gray-400" />
            {t("contactUs")}
          </Link>
          <Link to="/terms-and-conditions" className="flex items-center gap-2 cursor-pointer text-primary-500" aria-label="Privacy Policy">
            <FontAwesomeIcon icon={faShieldAlt} className="text-gray-400" />
            {t("privacyPolicy")}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
