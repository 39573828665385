import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faUser,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import CurrencySelect from "./CurrencySelector";
import { logout } from "../redux/authSlice";
import LoginModal from "../components/Modals/LoginModal"; // Import the LoginModal

const Navbar = ({ onLanguageChange }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [showLoginModal, setShowLoginModal] = useState(false); // State for login modal
  const [showDropdown, setShowDropdown] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };
  const handleLoginSuccess = () => {
    // Simulate logging in successfully
    setShowLoginModal(false);
  };
  return (
    <>
      <nav className="flex flex-row items-center justify-between py-4 px-6 md:px-20 bg-primary-500 text-white">
        {/* Logo and App Name */}
        <div className="flex items-center">
          <Link to="/">
            <img
              src={logo}
              alt="Sabeel Logo"
              className="h-10 md:h-12 w-10 md:w-12 mx-2"
            />
          </Link>
          <Link to="/">
            <span
              className="text-xl md:text-2xl"
              // style={{ fontFamily: "Rakkas, sans-serif" }}
            >
              {t("sabeel")}
            </span>
          </Link>
        </div>

        <div className="flex flex-col items-center">
          <div className="block md:hidden">
            <button onClick={() => setShowDropdown(!showDropdown)}>
              <FontAwesomeIcon icon={faBars} className="text-white" />
            </button>
          </div>

          <div
            className={`md:flex ${
              showDropdown ? "block" : "hidden"
            } flex-col md:flex-row items-center content-center gap-3 mt-3 md:mt-0`}
          >
            <select
              value={i18n.language}
              onChange={onLanguageChange}
              className="flex items-center bg-black bg-opacity-30 py-1 rounded cursor-pointer mt-2 md:mt-0 z-100"
            >
              <option value="ar">{t("arabic")}</option>
              <option value="en">{t("english")}</option>
            </select>
            <CurrencySelect />
            {isAuthenticated ? (
              <div
                className="flex items-center bg-black bg-opacity-30 px-4 py-2 rounded cursor-pointer mt-2 md:mt-0"
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="mx-1" />
                <span>{t("logout")}</span>
              </div>
            ) : (
              <div
                className="flex items-center bg-black bg-opacity-30 px-4 py-2 rounded cursor-pointer mt-2 md:mt-0"
                onClick={handleLoginClick}
              >
                <FontAwesomeIcon icon={faUser} className="text-white mx-1" />
                <span>{t("login")}</span>
              </div>
            )}
          </div>
        </div>
      </nav>

      {/* Render Login Modal */}
      <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal} handleLoginSuccess={handleLoginSuccess} />
    </>
  );
};

export default Navbar;

