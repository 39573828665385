// src/store/orderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  product: null,
  quantity: null,
  price: null,
  selectedPlacesList: [],
  isMosqueSelected: false,
  subscriptionPeriod: null, // Default to 2 months
  firstOrderDate: "", // Default to empty
  orderType:1, //1-single 2-for subscribtion
  orderCoupon:"",
  orderIds:[]
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderDetails(state, action) {
      state.product = action.payload.product;
      state.quantity = action.payload.quantity;
      state.price = action.payload.price;
      state.selectedPlacesList = action.payload.selectedPlacesList;
    },
    setIsMosqueSelected(state, action) {
      state.isMosqueSelected = action.payload;
    },
    setSubscriptionPeriod(state, action) {
      state.subscriptionPeriod = action.payload;
    },
    setFirstOrderDate(state, action) {
      state.firstOrderDate = action.payload;
    },
    setOrderType(state,action){
      state.orderType=action.payload
    },
    setOrderCoupon(state,action){
      state.orderCoupon=action.payload
    },
    setOrderIds(state,action){
      state.orderIds=action.payload
    },
    resetOrderDetails(state) {
      return initialState;
    },
  },
});

export const { setOrderDetails, setIsMosqueSelected, setSubscriptionPeriod, setFirstOrderDate, resetOrderDetails,setOrderType,setOrderCoupon,setOrderIds } = orderSlice.actions;

export default orderSlice.reducer;
