import React from "react";
import mosque from "../assets/images/mosque.png";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const termsArray = t("termsAndConditionsList", { returnObjects: true });
  const userGeneratedContentList = t("userGeneratedContentList", {
    returnObjects: true,
  });
  const thirdPartyWebsitesList = t("thirdPartyWebsitesList", {
    returnObjects: true,
  });
  const intellectualPropertyList = t("intellectualPropertyList", {
    returnObjects: true,
  });
  const privacyList=t("privacyList", {
    returnObjects: true,
  });
  const deliveryList=t("deliveryList", {
    returnObjects: true,
  });
const serviceProviderTermsList=t("serviceProviderTermsList", {
  returnObjects: true,
});
const privacyPolicyList=t("privacyPolicyList", {
  returnObjects: true,
});

  return (
    <div>
      <div className="  px-16 py-6">
        <div className="flex flex-col items-center justify-center ">
          <img src={mosque} alt="Mosque" className="w-full md:w-2/3 mb-4" />
        </div>
        <div className="w-[85%]">
          <h2 className="text-2xl font-bold text-primary-500 mb-2">
            {t("termsAndConditions")}
          </h2>

          <p> {t("usageTerms")}</p>

          <p>{t("termsApplication")}</p>

          <p>{t("termsAgreement")}</p>

          <p>{t("definitionsHeading")}</p>

          <p>{t("definitions")}</p>

          <p>&nbsp; {t("termsAndConditions")}</p>

          {termsArray.map((item, index) => (
            <p key={index}>{item}</p>
          ))}

          <p> {t("userGeneratedContent")}</p>

          {userGeneratedContentList.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          <p> {t("thirdPartyWebsites")}</p>
          {thirdPartyWebsitesList.map((item, index) => (
            <p key={index}>{item}</p>
          ))}

          <p> {t("intellectualProperty")}</p>
          
          <p> {t("thirdPartyWebsites")}</p>
          {intellectualPropertyList.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          <p>{t("privacy")}</p>

          {privacyList.map((item, index) => (
            <p key={index}>{item}</p>
          ))}

          <p> {t("delivery")}</p>
          {deliveryList.map((item, index) => (
            <p key={index}>{item}</p>
          ))}

          <p> {t("serviceProviderTerms")}</p>

          {serviceProviderTermsList.map((item, index) => (
            <p key={index}>{item}</p>
          ))}

          <h2>{t("privacyPolicy")}</h2>
          
          {privacyPolicyList.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
