import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

const NotAvailablePlace = ({ setShowNotAvailabePlaceModal, handleCategoryClick }) => {
  const { t } = useTranslation();
  const handleCategorySelect = (category) => {
    handleCategoryClick(category); // Handle the category selection
    setShowNotAvailabePlaceModal(false); // Close the modal
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] sm:w-[40%] md:w-[30%] relative">
        {/* Close button */}
        <button
          onClick={() => setShowNotAvailabePlaceModal(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>

        {/* Content */}
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-4"> {t("sorry")}</h2>
          <p> {t("deliveryNotAvailableInThisArea")}</p>
          <div className="flex flex-col gap-4 justify-center mt-5 sm:flex-row">
            <div
              onClick={() => handleCategorySelect("mecca")}
              className="px-4 py-2 rounded-lg cursor-pointer border shadow-custom bg-white border-gray-300 text-center hover:bg-gray-50 transition"
            >
              {t("showMakkaMosques")}
            </div>
            <div
              onClick={() => handleCategorySelect("pilgrims")}
              className="px-4 py-2 rounded-lg cursor-pointer border shadow-custom bg-white border-gray-300 text-center hover:bg-gray-50 transition"
            >
              {t("showPilgrimsMosques")}
            </div>
            <div
              onClick={() => handleCategorySelect("medina")}
              className="px-4 py-2 rounded-lg cursor-pointer border shadow-custom bg-white border-gray-300 text-center hover:bg-gray-50 transition"
            >
              {t("showMedinaMosques")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAvailablePlace;
