import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currency: 'SAR', // Default to SAR
  countryCode:'SA'
};

const currencySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload; // Update the currency in state
    },

    setCountryCode: (state, action) => {
      state.countryCode = action.payload; // Update the currency in state
    },
  },
});

export const { setCurrency ,setCountryCode} = currencySlice.actions;
export default currencySlice.reducer;
