import React, { createContext, useContext, useState } from 'react';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [orderDetails, setOrderDetails] = useState({
    product: null,
    quantity: null,
    price: null,
    selectedPlacesList: [],
  });
  const [isMosqueSelected, setIsMosqueSelected] = useState(false);

  return (
    <OrderContext.Provider value={{ orderDetails, setOrderDetails, isMosqueSelected, setIsMosqueSelected }}>
      {children}
    </OrderContext.Provider>
  );
};

// Export the context itself so it can be used in other components
export const useOrder = () => {
  return useContext(OrderContext);
};

// Export OrderContext for direct use
export { OrderContext };
