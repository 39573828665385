import { api_url } from "../config/config";
import { authorizedHttpJson, httpJson } from "../config/http";



export async function getPaymentOnlineStatus(countryCode) {
  return await authorizedHttpJson.get(`${api_url}/setting/paymentOnline`, {
    params: {
      countryCode: countryCode,
    },
  });
}

export async function getBanks() {
  return await authorizedHttpJson.get(`${api_url}/setting/banks`);
}

