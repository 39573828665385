import { api_url } from "../config/config";
import { authorizedHttpJson, httpJson } from "../config/http";




export async function createNewSuggetion(data) {
  return await authorizedHttpJson.post(`${api_url}/customer/suggetion`,data);
}
export async function resendCode(data) {
    return await httpJson.post(`${api_url}/customer/resend`,data);
  }

