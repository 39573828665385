import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import Layout from "./components/Layout";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "./redux/languageSlice";
import "react-phone-input-2/lib/style.css";
import { OrderProvider } from "./OrderContext";
import Home from "./pages/Home";
import MosqueMap from "./pages/MosqueMap";
import ContactUs from "./pages/ContactUs";
import MonthlySubscription from "./pages/MonthlySubscription";
import HousesMap from "./pages/HousesMap";
import Payment from "./pages/Payment";
import OurVision from "./pages/OurVision";
import TermsAndConditions from "./pages/TermsAndConditions";
import PaymentResults from "./pages/PaymentResults";
import { store, persistor } from "./redux/store"; // Adjust the path as necessary
import { Provider } from "react-redux"; // Import Provider

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // Get the selected language from Redux state
  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    // Set the i18n language based on the Redux state
    i18n.changeLanguage(language);
  }, [language]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    dispatch(setLanguage(selectedLanguage));
  };

  return (
    <Provider store={store}>
      {" "}
      {/* Wrap your app with Provider */}
      <OrderProvider>
        <PersistGate loading={null} persistor={persistor}>
          {" "}
          {/* Wrap with PersistGate */}
          <Router>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <PublicRoute
                    component={
                      <Layout onLanguageChange={handleLanguageChange}>
                        <Home />
                      </Layout>
                    }
                  />
                }
              />
              <Route
                path="/mosques-map"
                element={<PublicRoute component={<MosqueMap onLanguageChange={handleLanguageChange} />} />}
              />

              <Route
                path="/map"
                element={<PublicRoute component={<HousesMap onLanguageChange={handleLanguageChange} />} />}
              />
              <Route
                path="/contact-us"
                element={
                  <PublicRoute
                    component={
                      <Layout onLanguageChange={handleLanguageChange}>
                        <ContactUs />
                      </Layout>
                    }
                  />
                }
              />

              <Route
                path="/monthly-subscription"
                element={
                  <PublicRoute
                    component={
                      <Layout onLanguageChange={handleLanguageChange}>
                        <MonthlySubscription />
                      </Layout>
                    }
                  />
                }
              />

              <Route
                path="/payment"
                element={
                  <PublicRoute
                    component={
                      <Layout onLanguageChange={handleLanguageChange}>
                        <Payment />
                      </Layout>
                    }
                  />
                }
              />
              <Route
                path="/terms-and-conditions"
                element={
                  <PublicRoute
                    component={
                      <Layout onLanguageChange={handleLanguageChange}>
                        <TermsAndConditions />
                      </Layout>
                    }
                  />
                }
              />
              <Route
                path="/our-vision"
                element={
                  <PublicRoute
                    component={
                      <Layout onLanguageChange={handleLanguageChange}>
                        <OurVision />
                      </Layout>
                    }
                  />
                }
              />

              <Route
                path="/payment-result"
                element={
                  <PublicRoute
                    component={
                      <Layout onLanguageChange={handleLanguageChange}>
                        <PaymentResults />
                      </Layout>
                    }
                  />
                }
              />
            </Routes>
          </Router>
        </PersistGate>
      </OrderProvider>
    </Provider>
  );
}

export default App;
