import React from 'react';
import { useTranslation } from "react-i18next";

const MosqueModal = ({ selectedMosque, addMosqueToList, setShowModal }) => {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] sm:w-[60%] md:w-[40%] lg:w-[30%]">
        <p className="text-center"> {t("addTheMosqueToTheList")}</p>
        <h2 className="text-lg mb-4 mt-3 font-bold text-center">{selectedMosque.name}</h2>
        <div className="flex flex-col sm:flex-row gap-3">
          <button
            className="w-full bg-primary-500 text-white py-2 px-4 rounded-lg"
            onClick={addMosqueToList}
          >
           {t("addToTheList")}
          </button>
          <button
            className="w-full bg-gray-50 text-dark border border-black border-opacity-[20%] py-2 px-4 rounded-lg"
            onClick={() => setShowModal(false)}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MosqueModal;
