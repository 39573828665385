import { api_url } from "../config/config";
import { authorizedHttpJson, httpJson } from "../config/http";



export async function userLogin(data) {
  return await httpJson.post(`${api_url}/customer/login`, data);
}

export async function validationCode(data) {
  return await httpJson.post(`${api_url}/customer/validation`, data);
}


export async function userLogout() {
  return await authorizedHttpJson.post(`${api_url}/logout`);
}
