// src/store/orderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    date: new Date().toLocaleDateString(), // Current date
    time: new Date().toLocaleTimeString(), // Current time
    total:[],
    totalAfterCoupon:0,
    isCouponApplied:false,
    currency:"",
    qrCodes:[],
    selectedMethod:""
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentData(state, action) {
      state.date = action.payload.date;
      state.time = action.payload.time;
      state.total = action.payload.total;
      state.totalAfterCoupon=action.payload.totalAfterCoupon;
      state.isCouponApplied=action.payload.isCouponApplied;
      state.currency=action.payload.currency;
      state.qrCodes=action.payload.qrCodes;
      state.selectedMethod=action.payload.selectedMethod;
    },
    resetPayment(state) {
      return initialState;
    },
  },
});

export const { setPaymentData,resetPayment } = paymentSlice.actions;

export default paymentSlice.reducer;
