import React from "react";
import Autocomplete from "react-google-autocomplete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchInput = ({ placeholder, onSearch }) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  //console.log(googleMapsApiKey)
                          
  const handlePlaceSelect = (place) => {
    if (place && place.formatted_address) {
      onSearch(place);
    }
  };

  return (
    <div className="relative flex items-center  border border-black border-opacity-[20%] rounded-lg shadow-custom ">
      {/* Search Icon */}
      <FontAwesomeIcon 
        icon={faSearch} 
        className="absolute left-3 text-gray-400"
      />
      
      {/* Autocomplete Input */}
      <Autocomplete
        apiKey={googleMapsApiKey}
        onPlaceSelected={handlePlaceSelect}
        options={{
          types: ['address'],  // Search all places instead of cities
        }}
        placeholder={placeholder}
        className="w-full pl-10 pr-4 py-2 border border-gray-50 rounded-lg focus:outline-none"
      />
    </div>
  );
};

export default SearchInput;
