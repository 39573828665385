import { createSlice } from '@reduxjs/toolkit';
import i18n from '../i18n/i18n'; // Import the i18n config

// Get the initial language from i18n, fallback to Arabic ('ar') if no language is set
const initialState = {
  language: i18n.language || 'ar', // Use i18n's current language or default to Arabic
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload; // Update language state
      i18n.changeLanguage(action.payload); // Change i18n language
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
