import axios from "axios";
import { store } from "../redux/store"; // Import the Redux store

// Function to get the token from Redux
const getTokenFromStore = () => {
  const state = store.getState(); // Get the current state from Redux
  return state.auth.token ? `Bearer ${state.auth.token}` : "";
};

// Create axios instances
export let httpJson = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

export let httpForm = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
  },
});

export let authorizedHttpJson = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

export let authorizedHttpForm = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
  },
});

// Add interceptors to set the Authorization header dynamically for authorized requests
authorizedHttpJson.interceptors.request.use((config) => {
  const token = getTokenFromStore(); // Fetch token before each request
  if (token) {
    config.headers.Authorization = token; // Add the token to headers
  }
  return config;
});

authorizedHttpForm.interceptors.request.use((config) => {
  const token = getTokenFromStore(); // Fetch token before each request
  if (token) {
    config.headers.Authorization = token; // Add the token to headers
  }
  return config;
});
