import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import cooler from "../../assets/images/cooler.png";
import water from "../../assets/images/water.png";
import { useDispatch } from 'react-redux'; // Import useDispatch
import { useSelector } from "react-redux";
import { 
    setOrderDetails, 
    setIsMosqueSelected, 
    setOrderType, 
} from '../../redux/orderSlice'; // Import actions
import { useTranslation } from "react-i18next";
import {getProducts} from "../../api/products";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const OrderModal = ({ setShowOrderModal, selectedPlacesList = [] }) => {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [selectedOption, setSelectedOption] = useState(null);
    const [orderType, setOrderTypeSelect] = useState("1");
    const language = useSelector((state) => state.language.language);
    const selectedCountryCode = useSelector((state) => state.country.countryCode);
    const [products, setProducts] = useState([]);
    const waterQuantities = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000, 30000, 40000, 50000]; 
    const coolerQuantities = Array.from({ length: 10 }, (_, i) => i + 1);
    const auth = useSelector(state => state.auth);
    const [quantity, setQuantity] = useState(1);
    useEffect(() => {
        getProducts(selectedCountryCode)
          .then((response) => {
            setProducts(response.data.data);
           
            
          })
          .catch((error) => {
            console.error(error);
          });
      }, [selectedCountryCode]);
    // Get the product details based on selection
    const waterProduct = products.find(product => product.slug === 'Water');
    const coolerProduct = products.find(product => product.slug === 'coolers');
    const waterProductCurrency = language === 'ar' ? waterProduct?.currency : waterProduct?.currency_en;
    const coolerProductCurrency = language === 'ar' ? waterProduct?.currency : waterProduct?.currency_en;
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create dispatch instance

    const handleNextStep = () => setStep(prev => Math.min(prev + 1, 4)); // Update max step to 4
    const handlePrevStep = () => setStep(prev => Math.max(prev - 1, 1));

    const handleQuantitySelect = (quantity) => {
        setQuantity(quantity);
        const numberOfSelectedPlaces = selectedPlacesList.length;

        // Calculate the minimum quantity based on the number of selected places
        const minimumWaterQuantity = numberOfSelectedPlaces * 10;
    
        // Check for water
        if (selectedOption === 'water' && quantity < minimumWaterQuantity) {
            toast.info(t("minimumQuantityMessageWater"));
            return;
        }
    
        const selectedPrice = selectedOption === 'water' ? quantity * waterProduct.price : quantity * coolerProduct.price;

        // Update order details in Redux
        dispatch(setOrderDetails({
            product: selectedOption,
            quantity,
            price: selectedPrice,
            selectedPlacesList:selectedPlacesList,
        }));

        handleNextStep();
    };

    const handleOrderTypeChange = (e) => {
        setOrderTypeSelect(e.target.value);
        
    };

    const handleConfirm = () => {

        const orderDetails = {
            price: selectedOption === 'water' ? quantity * waterProduct.price : quantity * coolerProduct.price,
            currency: selectedOption === 'water' ? waterProduct.currency_en : coolerProduct.currency_en,
            item_ids: [selectedOption === 'water' ? 525 : 524],
            item_category: selectedOption,
            number_items: quantity,
            uuid_c1: auth.user ? auth.user.id : '',
            user_email: auth.user ? auth.user.email : '',
            user_phone_number: auth.user ? auth.user.phone : '',
            user_hashed_email: auth.user ? auth.user.email : '', 
            user_hashed_phone_number: auth.user ? auth.user.phone : ''
        };

        if (window.snaptr) {
            window.snaptr('track', 'ADD_CART', orderDetails);
        } else {
            console.warn('Snaptr is not loaded');
        }

        // Google Tag Manager Event
if (window.dataLayer) {
    window.dataLayer.push({
      event: "add_to_cart", // Custom event name for GTM
      value: orderDetails.price,
      currency: orderDetails.currency,
      item_ids: orderDetails.item_ids,
      item_category: orderDetails.item_category,
      num_items: orderDetails.number_items,
      user_id: orderDetails.uuid_c1,
      user_email: orderDetails.user_email,
      user_phone_number: orderDetails.user_phone_number,
    });
    console.log("Add to Cart event sent to GTM");
  } else {
    console.warn("GTM dataLayer is not loaded");
  }


  // Send event to TikTok Pixel
  if (window.ttq) {
    window.ttq.track('AddToCart', {
        value: orderDetails.price,
        currency: orderDetails.currency,
        content_ids: orderDetails.item_ids,
        content_type: "product",
        description:'Add to cart',
        quantity: orderDetails.number_items,
    });
  } else {
    console.warn('TikTok Pixel (ttq) is not loaded');
  }

        // Dispatch the selected values
        dispatch(setIsMosqueSelected(selectedPlacesList.length > 0)); // Update isMosqueSelected based on selected places
        dispatch(setOrderType(orderType))
        if (orderType === '2') {
            navigate('/monthly-subscription');
        } else if (orderType === '1') {
            navigate('/payment');
        }
    };

    return (
        <>
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] md:w-[43%] relative">
                <div className="flex justify-between items-center mb-4">
                    <div className="flex justify-start items-center">
                    {step > 1 && (
  <FontAwesomeIcon
    icon={faArrowLeft }
    onClick={handlePrevStep}
    className="cursor-pointer mx-3"
  />
)}
                    </div>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="cursor-pointer text-gray-500 hover:text-gray-700"
                        onClick={() => setShowOrderModal(false)}
                        aria-label="Close modal"
                    />
                </div>

                {step === 1 && (
                    <div className="p-2">
                        <h2 className="text-xl font-bold text-center">{t("chooseProduct")}</h2>
                        <div className="flex justify-center gap-10 mt-4">
                            <div
                                onClick={() => {
                                    setSelectedOption('water');
                                    handleNextStep();
                                }}
                                className="cursor-pointer text-center"
                                aria-label="Select water product"
                            >
                                <img src={water} alt="سبيل مياه" className='w-40' />
                                <p className='mt-3 text-primary-500 font-bold'>{t("sabeelWater")}</p>
                            </div>
                            <div className="border-r border-black border-opacity-[20%]"></div>
                            <div
                                onClick={() => {
                                    setSelectedOption('cooler');
                                    handleNextStep();
                                }}
                                className="cursor-pointer text-center"
                                aria-label="Select cooler product"
                            >
                                <img src={cooler} alt="سبيل ثلاجات" className='w-40' />
                                <p className='mt-3 text-primary-500 font-bold'>{t("sabeelCooler")}</p>
                            </div>
                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div className="p-2">
                        <div className=' px-3 pt-3 '>
                            <p className='text-center font-bold'>{t("chooseQuantityForMosque")}</p>
                            <div className="overflow-x-auto mt-4">
                                <div className="flex gap-3 mb-3">
                                    {(selectedOption === 'water' ? waterQuantities : coolerQuantities).map(quantity => (
                                        <div
                                            key={quantity}
                                            onClick={() => handleQuantitySelect(quantity)}
                                            className="border border-black border-opacity-[20%] rounded-lg p-2 text-center cursor-pointer hover:bg-gray-50 min-w-[150px]"
                                        >
                                            <img src={selectedOption === 'water' ? water : cooler} alt="item" />
                                            <p className='font-bold'>{selectedOption === 'water' ? `${quantity} ${t("carton")}` : `${quantity} ${t("cooler")}`}</p>
                                            <p className='text-primary-500'>
                                                {(quantity * (selectedOption === 'water' ? waterProduct.price : coolerProduct.price)).toFixed(2)} {selectedOption === 'water' ? waterProductCurrency: coolerProductCurrency}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {step === 3 && (
                    <div className="p-2">
                        <div className="flex justify-start items-center">
                            <div className="flex-grow flex justify-center">
                                <h2 className="text-xl font-bold "> {t("orderType")}</h2>
                            </div>
                        </div>
                        <hr className="my-4" />
                        <div>
                            <div className="flex gap-5 my-6">
                                <label className="block w-1/2">
                                    <input
                                        type="radio"
                                        name="orderType"
                                        value="1"
                                        className='mx-2'
                                        onChange={handleOrderTypeChange}
                                        checked={orderType === '1'} // Checked if orderType is 1
                                    /> {t("onlyOnce")}
                                </label>
                                <label className="block w-1/2">
                                    <input
                                        type="radio"
                                        name="orderType"
                                        value="2"
                                        className='mx-2'
                                        onChange={handleOrderTypeChange}
                                        checked={orderType === '2'} // Checked if orderType is 2
                                    /> {t("monthlySubscription")}
                                </label>
                            </div>
                            <div className="flex gap-5 mt-4">
                                <button
                                    onClick={handleConfirm}
                                    className="px-4 py-2 bg-primary-500 text-white rounded hover:bg-primary-600 transition-colors w-[100%] md:w-[50%]"
                                >
                                    {t("confirmOrder")}
                                </button>
                                <button
                                    onClick={handlePrevStep}
                                    className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400 transition-colors w-[100%] md:w-[50%]"
                                >
                                    {t("back")}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

              
            </div>
        </div>
        <ToastContainer />
        </>
    );
};

export default OrderModal;
