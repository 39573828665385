import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCheckPaymentStatus } from "../api/orders"; // Ensure this import path is correct
import { updateOrderForBank } from "../api/orders";
import { setPaymentData } from "../redux/paymentSlice";
import { useDispatch } from "react-redux";

const PaymentResults = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentStatusErrors, setPaymentStatusErrors] = useState("");
  const ids = useSelector((state) => state.order.orderIds);
  const coupon = useSelector((state) => state.order.orderCoupon);
  const selectedCountryCode = useSelector((state) => state.country.countryCode);
  const selectedMethod = useSelector((state) => state.payment.selectedMethod);
  const payment = useSelector((state) => state.payment);
  const getQueryParams = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const verifyPaymentStatus = async (resourcePath) => {
    const data = {
      checkoutpath: resourcePath,
      order_ids: ids,
    };

    try {
      const response = await getCheckPaymentStatus(data, selectedCountryCode);
      console.log(response);
      if (response.data.status == "success") {
        console.log(response.data.status);
        try {
          const paymentByBankResponse = await updateOrderForBank({
            payment_method: selectedMethod,
            order_ids: ids,
            coupon:coupon
          });

          if (paymentByBankResponse.data.status === true) {
            dispatch(
              setPaymentData({
                ...payment,
                date: new Date().toLocaleDateString(),
                time: new Date().toLocaleTimeString(),
                qrCodes: paymentByBankResponse.data.data,
              })
            );
          }
        } catch (error) {}
        setPaymentStatus("success");
        setPaymentStatusErrors("");
        navigate("/payment?status=success"); // Navigate to /payment with success query param
      } else if (response.data?.data?.status == "fail") {
        console.log(response.data?.data?.status);
        setPaymentStatus("failure");
        setPaymentStatusErrors(response.data.data.description);
        const errorMessage = response.data.data.description;
        navigate(
          `/payment?status=failed&error=${encodeURIComponent(errorMessage)}`
        ); // Navigate to /payment with failed query param
      }
    } catch (error) {
      console.log(error);
      setPaymentStatus("failure");
      setPaymentStatusErrors(error.response?.data?.message);
      const errorMessage = error.message;
      navigate(
        `/payment?status=failed&error=${encodeURIComponent(errorMessage)}`
      ); // Navigate to /payment with failed query param in case of error
    }
  };

  useEffect(() => {
    const resourcePath = getQueryParams("resourcePath");

    if (resourcePath) {
      verifyPaymentStatus(resourcePath);
    }
  }, [location]);

  return <>{/* You can display the payment status or error message here */}</>;
};

export default PaymentResults;
