import React from 'react';
import { useSelector } from 'react-redux';
import Navbar from './Navbar'; // Import the Navbar component
import Footer from './Footer'; // Import a Footer component (we'll create this next)

const Layout = ({ children, onLanguageChange }) => {
  const language = useSelector((state) => state.language.language);

  // Apply ltr-layout if the language is English; otherwise, use the default rtl-layout.
  const layoutClass = language === 'en' ? 'ltr-layout ' : 'rtl-layout';
  
  return (
    <div className={`${layoutClass}`}>
      <Navbar onLanguageChange={onLanguageChange} />
      <main className=" min-h-[550px] ">
        {children} {/* This will render the content passed to the Layout */}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
